import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store/root-reducer";
import Teams from "./teams";
import {
  assignClientRequest,
  deleteTeamMemberRequest,
  getAgencyUsersRequest,
  inviteUserRequest,
  toggleUserStatusRequest,
} from "./extra-action";
import { GetAgencyUsersRequest } from "./types";
import { AssignClientRequest } from "./types/request/assign-client-request";
import { GetClientsRequest } from "../clients/types";
import { getAgencyUserClientsRequest } from "../clients/extra-actions";
import { resetGetAgencyUserClientsState } from "../clients/clients-slice";
import {
  resetAssignClientsState,
  resetDeleteAgencyMemberState,
  resetInviteTeamMemberState,
} from "./teams-slice";

const mapStateToProps = (state: RootState) => ({
  getAgencyUsersRequestStatus: state.teams.getAgencyUsersRequest.status,
  getAgencyUsersRequestMessage: state.teams.getAgencyUsersRequest.message,
  inviteUserRequestStatus: state.teams.inviteUserRequest.status,
  inviteUserRequestMessage: state.teams.inviteUserRequest.message,
  agencyUsers: state.teams.agencyUsers,
  toggleUserStatusRequestStatus: state.teams.toggleUserStatusRequest.status,
  toggleUserStatusRequestMessage: state.teams.toggleUserStatusRequest.message,
  agencyUserClients: state.clients.agencyUserClients,
  clientsCount: state.clients.agencyUserClientCount,
  assignClientRequestStatus: state.teams.assignClientRequest.status,
  assignClientRequestMessage: state.teams.assignClientRequest.message,
  getAgencyUserClientsRequestStatus:
    state.clients.getAgencyUserClientsRequest.status,
  getAgencyUserClientsRequestMessage:
    state.clients.getAgencyUserClientsRequest.message,
  deleteTeamMemberRequestStatus: state.teams.deleteTeamMemberRequest.status,
  deleteTeamMemberRequestMessage: state.teams.deleteTeamMemberRequest.message,
  role: state.home.role,
  userId: state.home.userId,
  firstName: state.home.firstName,
  planCode: state.home.subscription?.planCode,
  adminFirstName: state.home.adminDetails?.firstName,
});

const mapDispatchToProps = {
  sendGetAgencyUsersRequest: (params: GetAgencyUsersRequest) =>
    getAgencyUsersRequest(params),
  sendGetClientsRequest: (params: GetClientsRequest) =>
    getAgencyUserClientsRequest(params),
  sendInviteUserRequest: (payload) => inviteUserRequest(payload),
  sendToggleUserStatusRequest: (agencyUserId: number) =>
    toggleUserStatusRequest({ agencyUserId }),
  sendAssignClientRequest: (
    agencyUserId: number,
    payload: AssignClientRequest
  ) => assignClientRequest({ agencyUserId, ...payload }),
  sendDeleteTeamMemberRequest: (agencyUserId: number) =>
    deleteTeamMemberRequest({ agencyUserId }),
  resetAssignClientsState: () => resetAssignClientsState(),
  resetGetAgencyUserClientsState: () => resetGetAgencyUserClientsState(),
  resetDeleteAgencyMemberState: () => resetDeleteAgencyMemberState(),
  resetInviteTeamMemberState: () => resetInviteTeamMemberState(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector>;

export default connector(Teams);
