import React, { useState, useEffect } from "react";
import toaster, { Theme } from "@saleshandy/designs/lib/toaster";
import type { IProps } from "./teams-container";
import TeamListTable from "./components/team-list-table";
import TeamHeaderAction from "./components/team-header-action";
import InviteTeamMemberModal from "./components/invite-team-member-modal";
import { checkIsFreePlan, executeOnRequestStatus } from "../../shared/utils";
import AssignClientModal from "./components/assign-client-modal/assign-client-modal";
import { SortBy, Order, RequestStatus } from "../../shared/enum";
import { SortBy as TeamSortBy } from "../../shared/enum/team-sort-by";
import NoSearchFound from "../../shared/components/no-search-found/no-search-found";
import Loader from "../../shared/components/loader";
import DeleteTeamMemberModal from "./components/delete--team-member-modal/delete-team-member-modal";
import { getIsRequestPending } from "../../shared/utils/get-request-status";
import DisableMemberStatusConfirmationModal from "./components/disable-member-status-confirmation-modal/disable-member-status-confirmation.modal";
import PlanRestrictionModal from "../../shared/components/plan-restriction-modal";

const Teams: React.FC<IProps> = ({
  getAgencyUsersRequestStatus,
  getAgencyUsersRequestMessage,
  inviteUserRequestStatus,
  inviteUserRequestMessage,
  agencyUsers,
  toggleUserStatusRequestStatus,
  toggleUserStatusRequestMessage,
  agencyUserClients,
  clientsCount,
  assignClientRequestStatus,
  assignClientRequestMessage,
  getAgencyUserClientsRequestStatus,
  getAgencyUserClientsRequestMessage,
  deleteTeamMemberRequestStatus,
  deleteTeamMemberRequestMessage,
  role,
  userId,
  firstName,
  planCode,
  adminFirstName,
  sendGetAgencyUsersRequest,
  sendGetClientsRequest,
  sendInviteUserRequest,
  sendToggleUserStatusRequest,
  sendAssignClientRequest,
  sendDeleteTeamMemberRequest,
  resetAssignClientsState,
  resetGetAgencyUserClientsState,
  resetDeleteAgencyMemberState,
  resetInviteTeamMemberState,
}) => {
  const [isInviteTeamMemberModalShow, setIsInviteTeamMemberModalShow] =
    useState<boolean>(false);
  const [isAssignClientModalShow, setIsAssignClientModalShow] =
    useState<boolean>(false);
  const [isDeleteTeamMemberModalShow, setIsDeleteTeamMemberModalShow] =
    useState<boolean>(false);
  const [
    isDisableMemberConfirmationModalShow,
    setIsDisableMemberConfirmationModalShow,
  ] = useState<boolean>(false);
  const [isAgencyAdmin, setIsAgencyAdmin] = useState<boolean>(false);
  const [isSelfView, setIsSelfView] = useState<boolean>(false);
  const [agencyUserId, setAgencyUserId] = useState<number>();
  const [agencyUserName, setAgencyUserName] = useState<string>("");
  const [sortBy, setSortBy] = useState<TeamSortBy>(TeamSortBy.CreatedAt);
  const [order, setOrder] = useState<Order>(Order.Descending);
  const [memberId, setMemberId] = useState<number>();
  const [planRestrictionModal, setPlanRestrictionModal] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const showPlanRestrictionModal = () => {
    setPlanRestrictionModal(true);
  };

  const hidePlanRestrictionModal = () => {
    setPlanRestrictionModal(false);
  };

  const showInviteTeamMemberModal = () => {
    if (checkIsFreePlan(planCode)) {
      showPlanRestrictionModal();
    } else {
      setIsInviteTeamMemberModalShow(true);
    }
  };

  const hideInviteTeamMemberModal = () => {
    setIsInviteTeamMemberModalShow(false);
  };

  const showDisableMemberConfirmationModal = () => {
    setIsDisableMemberConfirmationModalShow(true);
  };

  const hideDisableMemberConfirmationModal = () => {
    setIsDisableMemberConfirmationModalShow(false);
  };

  const onToggleUserStatus = (checked, memberIdValue) => {
    setMemberId(memberIdValue);
    if (!checked) {
      showDisableMemberConfirmationModal();
    } else {
      sendToggleUserStatusRequest(memberId);
    }
  };

  const showAssignClientModal = (
    agencyUserIdValue: number,
    isAdmin: boolean,
    agencyUserNameValue: string
  ) => {
    setAgencyUserId(agencyUserIdValue);
    setAgencyUserName(agencyUserNameValue);
    setIsAgencyAdmin(isAdmin);
    setIsSelfView(userId === agencyUserIdValue);

    sendGetClientsRequest({
      page: 1,
      sortByKey: SortBy.CreatedAt,
      order: Order.Descending,
      withAssigneeData: true,
      agencyUserId: agencyUserIdValue,
    });
  };

  const hideAssignClientModal = () => {
    setIsAssignClientModalShow(false);
  };

  const fetchAgencyUsersData = (newPartialOptions) => {
    sendGetAgencyUsersRequest({
      page: 1,
      sortByKey: sortBy,
      order,
      ...newPartialOptions,
    });
  };

  // On Search
  const onSearchChangeHandler = (newSearch: string) =>
    fetchAgencyUsersData({ search: newSearch });

  const handleInviteTeamMember = (payload) => {
    sendInviteUserRequest(payload);
  };

  const onAssignClientSave = (
    clientsToBeAssigned,
    clientsToBeRemove,
    isAdminAccessChecked
  ) => {
    const payload = {
      ...(clientsToBeAssigned.length > 0 && {
        clientsToBeAssigned,
      }),
      ...(clientsToBeRemove.length > 0 && {
        clientsToBeRemoved: clientsToBeRemove,
      }),
      adminRole: isAdminAccessChecked,
    };
    sendAssignClientRequest(agencyUserId, payload);
  };

  // Delete Team Member
  const showDeleteTeamMemberModal = (agencyUserIdValue: number) => {
    setAgencyUserId(agencyUserIdValue);
    setIsDeleteTeamMemberModalShow(true);
  };

  const hideDeleteTeamMemberModal = () => {
    setIsDeleteTeamMemberModalShow(false);
  };

  const onDeleteTeamMember = () => {
    sendDeleteTeamMemberRequest(agencyUserId);
  };

  const onPageChangeHandler = (page) => {
    setCurrentPage(page);
    sendGetAgencyUsersRequest({
      page,
      sortByKey: sortBy,
      order,
    });
  };

  const onSortHandler = (sortByValue: TeamSortBy, orderValue: string) => {
    const sortOrder = orderValue === "asc" ? Order.Ascending : Order.Descending;
    setSortBy(sortByValue);
    setOrder(sortOrder);
    fetchAgencyUsersData({ sortByKey: sortByValue, order: sortOrder });
  };

  const onAssignClientPageChangeHandler = (page) => {
    sendGetClientsRequest({
      page,
      sortByKey: SortBy.CreatedAt,
      order: Order.Descending,
      withAssigneeData: true,
      agencyUserId,
    });
  };

  useEffect(() => {
    fetchAgencyUsersData({});

    return () => {
      resetInviteTeamMemberState();
    };
  }, []);

  useEffect(() => {
    executeOnRequestStatus({
      status: getAgencyUsersRequestStatus,
      onFailed: () => {
        toaster.error(getAgencyUsersRequestMessage);
      },
    });
  }, [getAgencyUsersRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: inviteUserRequestStatus,
      onSuccess: () => {
        hideInviteTeamMemberModal();
        toaster.success(inviteUserRequestMessage, { theme: Theme.New });
        fetchAgencyUsersData({});
      },
      onFailed: () => {
        if (inviteUserRequestMessage) {
          toaster.error(inviteUserRequestMessage);
        }
      },
    });
  }, [inviteUserRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: toggleUserStatusRequestStatus,
      onSuccess: () => {
        fetchAgencyUsersData({ page: currentPage });
        hideDisableMemberConfirmationModal();
      },
      onFailed: () => {
        toaster.error(toggleUserStatusRequestMessage);
      },
    });
  }, [toggleUserStatusRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: assignClientRequestStatus,
      onSuccess: () => {
        hideAssignClientModal();
        toaster.success(assignClientRequestMessage, { theme: Theme.New });
        fetchAgencyUsersData({ page: currentPage });
      },
      onFailed: () => {
        toaster.error(assignClientRequestMessage);
      },
    });
  }, [assignClientRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: deleteTeamMemberRequestStatus,
      onSuccess: () => {
        hideDeleteTeamMemberModal();
        toaster.success(deleteTeamMemberRequestMessage, { theme: Theme.New });
        fetchAgencyUsersData({});
      },
      onFailed: () => {
        toaster.error(deleteTeamMemberRequestMessage);
      },
    });
  }, [deleteTeamMemberRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: getAgencyUserClientsRequestStatus,
      onSuccess: () => {
        setIsAssignClientModalShow(true);
      },
      onFailed: () => {
        toaster.error(getAgencyUserClientsRequestMessage);
      },
    });
  }, [getAgencyUserClientsRequestStatus]);

  useEffect(
    () => () => {
      resetAssignClientsState();
      resetGetAgencyUserClientsState();
      resetDeleteAgencyMemberState();
    },
    []
  );

  if (getAgencyUsersRequestStatus === RequestStatus.Pending && !agencyUsers) {
    return <Loader />;
  }

  return (
    <>
      <div className='teams'>
        <TeamHeaderAction
          showInviteTeamMemberModal={showInviteTeamMemberModal}
          onChange={onSearchChangeHandler}
          role={role}
        />
        {agencyUsers && agencyUsers.length > 0 ? (
          <TeamListTable
            teamList={agencyUsers}
            userId={userId}
            role={role}
            sendToggleUserStatusRequest={(checked, memberIdValue) =>
              onToggleUserStatus(checked, memberIdValue)
            }
            showAssignClientModal={(
              agencyUserIdValue,
              isAdmin,
              agencyUserNameValue
            ) =>
              showAssignClientModal(
                agencyUserIdValue,
                isAdmin,
                agencyUserNameValue
              )
            }
            showDeleteTeamMemberModal={(agencyUserIdValue) =>
              showDeleteTeamMemberModal(agencyUserIdValue)
            }
            onPageChange={(page) => onPageChangeHandler(page)}
            sort={{
              sortBy,
              order,
            }}
            onSortHandler={(sortByValue, orderValue) =>
              onSortHandler(sortByValue, orderValue)
            }
          />
        ) : (
          <NoSearchFound />
        )}
      </div>
      <InviteTeamMemberModal
        show={isInviteTeamMemberModalShow}
        onClose={hideInviteTeamMemberModal}
        isRequestPending={getIsRequestPending(inviteUserRequestStatus)}
        onSubmit={(payload) => handleInviteTeamMember(payload)}
      />
      <AssignClientModal
        show={isAssignClientModalShow}
        isSelfView={isSelfView}
        role={role}
        onClose={hideAssignClientModal}
        onSubmit={(
          clientsToBeAssigned,
          clientsToBeRemove,
          isAdminAccessChecked
        ) =>
          onAssignClientSave(
            clientsToBeAssigned,
            clientsToBeRemove,
            isAdminAccessChecked
          )
        }
        clients={agencyUserClients}
        clientsCount={clientsCount}
        agencyUserName={agencyUserName}
        isAgencyAdmin={isAgencyAdmin}
        isRequestPending={getIsRequestPending(assignClientRequestStatus)}
        onPageChange={(page) => onAssignClientPageChangeHandler(page)}
      />

      <DeleteTeamMemberModal
        show={isDeleteTeamMemberModalShow}
        onClose={hideDeleteTeamMemberModal}
        onSubmit={onDeleteTeamMember}
        isRequestPending={getIsRequestPending(deleteTeamMemberRequestStatus)}
      />
      <DisableMemberStatusConfirmationModal
        show={isDisableMemberConfirmationModalShow}
        onClose={hideDisableMemberConfirmationModal}
        onSubmit={() => sendToggleUserStatusRequest(memberId)}
        isRequestPending={getIsRequestPending(toggleUserStatusRequestStatus)}
      />
      <PlanRestrictionModal
        show={planRestrictionModal}
        onClose={hidePlanRestrictionModal}
        role={role}
        adminFirstName={adminFirstName}
        userFirstName={firstName}
      />
    </>
  );
};

export default Teams;
