import React, { useState, useEffect } from "react";
import { Button, ImageIcon, Input, Modal } from "@saleshandy/designs";
import validator from "validator";
import { IProps } from "./types";
import { Images } from "../../../../shared/utils/app-constants";

const AddClientModal: React.FC<IProps> = ({
  show,
  onClose,
  onSubmit,
  clientName,
  isEditAction,
  isRequestPending,
}) => {
  const [clientNameValue, setClientNameValue] = useState<string>("");

  const onInputChange = (value) => {
    setClientNameValue(value);
  };

  useEffect(() => {
    if (clientName) {
      setClientNameValue(clientName);
    } else {
      setClientNameValue("");
    }
  }, [show]);

  return (
    <Modal
      show={show}
      titleContent={
        <div className='d-flex'>
          <ImageIcon src={Images.FileText} />
          <div className='ml-2 semibold-3'>
            {isEditAction ? "Edit client" : "Add new Client"}
          </div>
        </div>
      }
      showCloseIcon
      onClose={onClose}
      onHide={onClose}
      submitButtonText={isEditAction ? "Save" : "Add Client"}
      isSubmitLoading={isRequestPending}
      isSubmitDisabled={
        isRequestPending || validator.isEmpty(clientNameValue.trim())
      }
      isCancelDisabled={isRequestPending}
      cancelButtonVarient={Button.Variant.Outlined}
      onSubmit={() => onSubmit({ clientName: clientNameValue })}
      submitButtonClassName='btn-primary-disable'
      className='add-client-modal modal-no-header-border'
    >
      <Input
        placeholder='Enter client’s name here...'
        label='Name*'
        type='text'
        value={clientNameValue}
        onChange={(e) => onInputChange(e)}
        className='input-field'
      />
    </Modal>
  );
};

export default AddClientModal;
